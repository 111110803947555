import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MailIcon from '@mui/icons-material/Mail';
import useGetAllCanditates from '../hooks/useGetAllCanditates';
import StudentDelete from './StudentDelete';
import { useState } from 'react';
import ContactModule from 'app/mmEsoft/module/ContactModule';
import Batches from 'app/mmEsoft/module/Batches';

const StudentList = ({
  batchId,
  Swal,
  studentList,
  studentLoading,
  fetchCandidates,
}) => {
  const [openStuDel, setOpenStuDel] = useState(false);
  const [stuId, setStuId] = useState(null);

  const [openMail, setOpenMail] = useState(false);
  const handleMail = () => {
    ContactModule.postEmail(stuId, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Mail sent successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error sending Email',
        });
      }
    });
    setOpenMail(!openMail);
  };
  const handleSuspend = (id) => {
    let data = { suspended: true };
    Batches.suspendCandidate(id, data, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Student suspended successfully.',
        });
        fetchCandidates(batchId)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error suspending Student',
        });
      }
    });
  };
  const handleActivate = (id) => {
    let data = { suspended: 0, deleted: 0 };
    Batches.activateCandidate(id, data, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Student added successfully.',
        });
        fetchCandidates(batchId);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error activating Student',
        });
      }
    });
  };

  return (
    <>
      {studentLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>S.no</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentList.length === 0 ? (
                  <TableRow>
                    <TableCell align='center' colSpan={4}>
                      <Typography fontSize={'20px'} fontWeight={'600'}>
                        No Data
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  studentList.map((student, index) => {
                    return (
                      <TableRow key={student?.id}>
                        <TableCell sx={{ color: student.suspended ? 'gainsboro' : 'inherit' }}>{index + 1}</TableCell>
                        <TableCell sx={{ color: student.suspended ? 'gainsboro' : 'inherit',  }}>
                          {student?.name}{student.suspended ?
                            <Typography m={1} component="span" sx={{ color:'gainsboro', fontSize: "25px" }}>
                               !
                            </Typography> : ''}
                            
                             
                        </TableCell>
                        <TableCell sx={{ color: student.suspended ? 'gainsboro' : 'inherit' }}>{student?.email}</TableCell>
                        <TableCell sx={{ color: student.suspended ? 'gainsboro' : 'inherit' }}>{student?.contact}</TableCell>
                        <TableCell>
                          {student.suspended ? (
                            <IconButton
                              aria-label='activate'
                              onClick={() => handleActivate(student?.id)}
                            >
                              <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label='suspend'
                              onClick={() => handleSuspend(student?.id)}
                            >
                              <BlockIcon sx={{ color: 'red' }} />
                            </IconButton>
                          )}

                          <IconButton
                            aria-label='delete'
                            onClick={() => {
                              setStuId(student?.id);
                              setOpenStuDel(!openStuDel);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            aria-label='mail'
                            onClick={() => {
                              setStuId(student?.user_id);
                              setOpenMail(!openMail);
                            }}
                          >
                            <MailIcon color='success' />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <StudentDelete
        openStuDel={openStuDel}
        setOpenStuDel={setOpenStuDel}
        id={stuId}
        Swal={Swal}
        batchId={batchId}
        fetchCandidates={fetchCandidates}
      />
      <Dialog open={openMail} onClose={() => setOpenMail(!openMail)}>
        <DialogTitle color='red'>Email confirmation</DialogTitle>
        <DialogContent>
          <Typography
            marginY='10px'
            color='#212121'
            textAlign='center'
            sx={{ fontSize: '15px' }}
          >
            Are you sure you want to mail a student?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button size='small' variant='outlined' onClick={handleMail}>
            Mail
          </Button>
          <Button
            size='small'
            variant='contained'
            onClick={() => setOpenMail(!openMail)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StudentList;
