import appApi from './appApi';

class UserAnswers {
  addAnswers = (data, callBack) => {
    
    return appApi({
      method: 'POST',
      url: '/api/user_answer/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
        
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  question', error);
        
        
      });
  };

  getAllAnswersByUserId = (userId, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/user_answer/user/' + userId,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
        console.log(response.data);
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  exams', error);
      });
  };
  updateAnswerById = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/user_answer/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating  exam', error);
      });
  };
}

export default new UserAnswers();
