import React, { useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
const AddServer = props => {
  const contentRef = useRef(null);

  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('title field is required'),
      }),
      // title1: yup.string().when("title", {
      //   is: (title) => !title || title.length === 0,
      //   then: yup.string().required("At least one of the fields is required"),
      // }),
    },
    ['title']
  );
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, []);
  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close}>
        <Formik
          validateOnChange={true}
          initialValues={{
            title: props?.data?.title ? props?.data?.title : '',
            host: props?.data?.host ? props?.data?.host : '',
            port: props?.data?.port && props?.data?.port,
            password: props?.data?.password ? props?.data?.password : '',
            bindDn: props?.data?.bindDn ? props?.data?.bindDn : '',
            securityType: props?.data?.securityType
              ? props?.data?.securityType
              : '',
            base: props?.data?.base ? props?.data?.base : '',
            filter: props?.data?.filter ? props?.data?.filter : '',
            mapUsername: props?.data?.mapUsername
              ? props?.data?.mapUsername
              : '',
            mapFirstname: props?.data?.mapFirstname
              ? props?.data?.mapFirstname
              : '',
            mapLastname: props?.data?.mapLastname
              ? props?.data?.mapLastname
              : '',
            mapUserPassword: props?.data?.mapUserPassword
              ? props?.data?.mapUserPassword
              : '',
            mapEmail: props?.data?.mapEmail ? props?.data?.mapEmail : '',
            mapDepartment: props?.data?.mapDepartment
              ? props?.data?.mapDepartment
              : '',
            mapPhonenumber: props?.data?.mapPhonenumber
              ? props?.data?.mapPhonenumber
              : '',
            mapGroup: props?.data?.mapGroup ? props?.data?.mapGroup : '',
            mapGroupFilter: props?.data?.mapGroupFilter
              ? props?.data?.mapGroupFilter
              : '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            
            props.addLdapServer(data);

            props.close();
          }}
        >
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <DialogContent
              ref={contentRef}
              sx={{ overflowY: 'auto', maxHeight: '80vh' }}
            >
              <DialogTitle id="alert-dialog-title" textAlign="center">
                Add LDAP Server
              </DialogTitle>
              <Box
                sx={{
                  width: '500px',
                }}
              >
                <Div sx={{ mb: 2 }}>
                  <JumboTextField fullWidth name="title" label="Title" />
                </Div>
                {/* <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name='title1'
                    label='Title ( العربيه )'
                  />
                </Div> */}
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField fullWidth name="port" label="Port" />
                </Div>
                {props.data?.password ? (
                  <>
                    <Div sx={{ mb: 2, mt: 1 }}>
                      <JumboTextField
                        fullWidth
                        name="password"
                        label="Password"
                      />
                    </Div>
                  </>
                ) : null}
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    select
                    fullWidth
                    name="securityType"
                    label="Security Type"
                  >
                    <MenuItem key="Simple/Connection" value="simple_connection">
                      Simple Connection
                    </MenuItem>
                    <MenuItem key="LDAP/SSL" value="ldap_ssl">
                      LDAP/SSl{' '}
                    </MenuItem>
                    <MenuItem key="LDAP/TLS" value="ldap_tls">
                      LDAP/TLS{' '}
                    </MenuItem>
                  </JumboTextField>
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField fullWidth name="bindDn" label="Bind DN" />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField fullWidth name="base" label="Base " />
                </Div>{' '}
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField fullWidth name="filter" label="user Filter" />
                </Div>{' '}
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="mapUsername"
                    label="Map Username"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="mapUserPassword"
                    label="Map UserPassword"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="mapFirstname"
                    label="Map FirstName"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="mapLastname"
                    label="Map LastName"
                  />
                </Div>{' '}
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField fullWidth name="mapEmail" label="Map Email" />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="mapDepartment"
                    label="Map Department"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="mapPhonenumber"
                    label="Map PhoneNumber"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField fullWidth name="mapGroup" label="Map Group" />
                </Div>
                {/* <Div sx={{ mb: 2, mt: 1 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Enable Group Synchronization"
                    name="groupSync"
                  />
                </Div> */}
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="mapGroupFilter"
                    label="Map GroupFilter"
                  />
                </Div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type="submit" autoFocus>
                {props.edit ? 'Update' : 'Save'}
              </Button>
              <Button variant="contained" onClick={props.close}>
                {' '}
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddServer;
