import MarvelMindsApi, { appServerURLJava } from './MarvelMindsApi';
import appApi from '../../../../src/modules/appApi';
import axios from 'axios';

class Batches {
  getBatchByUserId = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/candidate_batch/all/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getting Batches');
      });
  };

  getBatchByUserIdd = (id, callback) => {
    axios
      .get(`${appServerURLJava}/public/mm-esoft/${id}/get-batch-user-id`)
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log(err, 'error occurred in getting candidate by id');
        callback({ status: 'error' });
      });
  };

  //allBatches
  getAllBatches = callBack => {
    return appApi({
      method: 'GET',
      url: `/api/batch/all`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getting Batches');
      });
  };

  //api/batch/add(POST) provide data

  addBatch = (data, callback) => {
    return appApi({
      method: 'POST',
      url: 'api/batch/add',
      data: data,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        callback({ status: 'error' });
        console.log(error, 'error occurred in adding Batches');
      });
  };

  //@Get /api/batch/1(GET) put id in place of 1
  getBatchById = (id, callback) => {
    return appApi({
      method: 'GET',
      url: `api/batch/${id}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        callback({ status: 'error' });
        console.log(error, 'error occurred in adding Batches');
      });
  };

  ///api/batch/1 (PUT) put id in place of 1 and provide data
  updateBatchById = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: `api/batch/${id}`,
      data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occsured in updating batch');
      });
  };

  // PENDING : /api/batch/12(DELETE) provide id in place of 12

  // api/candidate_batch/all/batchId/🆔
  getCandidates = async (id, callBack) => {
    try {
      const response = await appApi({
        method: 'GET',
        url: `api/candidate_batch/all/batchId/${id}`,
      });
      callBack({ status: 'success', data: response.data });
    } catch (error) {
      callBack({ status: 'error' });
      console.log(
        error,
        'Error Occurred while getting all candidates by bacth id '
      );
    }
  };

  deleteCandidate = async (id, callback) => {
    try {
      const response = await appApi({
        method: 'DELETE',
        url: `api/candidate_batch/delete/${id}`,
      });
      callback({ status: 'success', data: response.data });
    } catch (error) {
      callback({ status: 'error' });
      console.log(
        error,
        'Error Occurred while getting all candidates by bacth id '
      );
    }
  };

  suspendCandidate = async (id, data, callback) => {
    try {
      const response = await appApi({
        method: 'PUT',
        url: `api/candidate_batch/${id}`,
        data: data,
      });
      callback({ status: 'success', data: response.data });
    } catch (error) {
      callback({ status: 'error' });
      console.log(error, 'Error Occurred while suspending candidates');
    }
  };

  activateCandidate = async (id, data, callback) => {
    try {
      const response = await appApi({
        method: 'PUT',
        url: `api/candidate_batch/activate/${id}`,
        data: data,
      });
      callback({ status: 'success', data: response.data });
    } catch (error) {
      callback({ status: 'error' });
      console.log(error, 'Error Occurred while activating candidates');
    }
  };
}

export default new Batches();
