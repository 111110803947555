import React, { useRef } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import groups from 'modules/groups';

const AddServer = props => {
  const contentRef = useRef(null);
  const [group, setGroup] = React.useState();
  const validationSchema = yup.object().shape({
    title: yup.string().required('Title field is required'),
    groupId: yup.string().required('Group is required'),
  });
  React.useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
    getGroups();
  }, []);

  const getGroups = () => {
    groups.getAllGroups(response => {
      if (response.status === 'success') {
        setGroup(response.data);
      } else {
        setGroup('');
      }
    });
  };
  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close}>
        <Formik
          validateOnChange={true}
          initialValues={{
            ...props.data,

            groupId: props?.data?.groupId && props?.data?.groupId,
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            
            props.addSsoServer(data);
            props.close();
          }}
        >
          {({ values, setFieldValue }) => (
            <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
              <DialogContent
                ref={contentRef}
                sx={{ overflowY: 'auto', maxHeight: '80vh' }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  textAlign="center"
                  maxWidth="lg"
                >
                  Add SSO
                </DialogTitle>
                <Box
                  sx={{
                    width: '500px',
                  }}
                >
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField fullWidth name="title" label="Title" />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      select
                      name="groupId"
                      label="Group *"
                    >
                      {group &&
                        group.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.title1}
                          </MenuItem>
                        ))}
                    </JumboTextField>
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField fullWidth name="url" label="URL" />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="clientId"
                      label="Client ID"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="usernameParameter"
                      label="Username Parameter"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="emailParameter"
                      label="Email Parameter"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="firstNameParameter"
                      label="First Name Parameter"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="lastNameParameter"
                      label="Last Name Parameter"
                    />
                  </Div>

                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="departmentParameter"
                      label="Department Parameter"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField fullWidth name="Type" label="Type" />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="tokenParameter"
                      label="Token Parameter"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="tokenDecodeUrl"
                      label="Token Decode URI"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="tokenExchangeUrl"
                      label="Token Exchange URL"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="accessTokenParameter"
                      label="Access Token Parameter"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="accessTokenJwtParameter"
                      label="Access Token JWT Parameter"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="accessTokenExpiryParameter"
                      label="Access Token Expiry Parameter"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="accessRefreshTokenParameter"
                      label="Access Refresh Token Parameter"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="emailApiUrl"
                      label="Email API URL"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="clientSecret"
                      label="Client Secret"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="grantType"
                      label="Grant Type"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="resource"
                      label="Resource"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event =>
                            setFieldValue(
                              'randomQuestions',
                              event.target.checked
                            )
                          }
                          checked={values.randomQuestions}
                        />
                      }
                      label="Discoverable"
                      name="discoverable"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event =>
                            setFieldValue(
                              'randomQuestions',
                              event.target.checked
                            )
                          }
                          checked={values.randomQuestions}
                        />
                      }
                      label="Enable"
                      name="enable"
                    />
                  </Div>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" type="submit" autoFocus>
                  Save
                </Button>

                <Button variant="contained" onClick={props.close}>
                  {' '}
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddServer;
