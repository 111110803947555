import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { PlayArrow } from '@mui/icons-material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import campaigns from "modules/campaigns";
import videos from "modules/videos";
import userProgress from "modules/userProgress";
import questions from "modules/questions";
import { data } from "app/shared/metrics/SalesStatistics/data";
import lessons from "modules/lessons";

const CourseOverview = () => {
  const [lessonData, setLessonData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedLesson, setExpandedLesson] = useState(null);
  const [startedLessons, setStartedLessons] = useState({});
  const { id } = useParams();
  const { user } = useSelector(({ login }) => login);
  const [UserProgressData, setUserProgressData] = useState([]);
  const navigate = useNavigate();
  const { myBatch } = useSelector(({ batchesReducer }) => batchesReducer);
  const [isWatched,setIsWatched] = useState(false);
  const [UserProgressToSent,setUserProgressToSent] = useState();
  const [duration,setDuration] = useState();

  useEffect(() => {
    if (id && user.id) {
      fetchLessonsAndVideos(id);
      getUserProgressByCampaignId(id, user.id);

    }
  }, [id, user.id]);

     //check if Campaign is Already Started then dont show Start Campaign Button
     useEffect(() => {
      if (
        Array.isArray(UserProgressData) &&
        UserProgressData.every(
          (lesson) =>
            (lesson.videoStatus && lesson.quizStatus) || lesson.policyStatus
        )
      ) {
      }
    }, [UserProgressData]);

    const [videoDurations, setVideoDurations] = useState({});

    useEffect(() => {
      if (lessonData.length > 0) {
        const fetchDurations = async () => {
          const durations = {};
          for (const lesson of lessonData) {
            try {
              const duration = await getVideoDuration(lesson);
              durations[lesson.lessonId] = duration;

            } catch (error) {
              console.error(`Failed to fetch duration for lesson ${lesson.lessonId}`, error);
            }
          }
          setVideoDurations(durations);
          console.log('duration',durations);

        };
    
        fetchDurations();
      }
    }, [lessonData]); // Runs when lessonData is set
    
    const getVideoDuration = async (lesson) => {
      return new Promise((resolve, reject) => {
        videos.getVideoDuration(user.id, lesson.lessonId, (response) => {
          if (response.status === "success") {
            const savedDuration = response.data[0]?.duration || 0;
            resolve(savedDuration);
          } else {
            reject("Failed to fetch duration");
          }
        });
      });
    };
    

  const fetchLessonsAndVideos = async (campaignId) => {
    setLoading(true);
    campaigns.getLessonsByCampaignId(campaignId, async (response) => {
      if (response.status === "success" && Array.isArray(response.data)) {
        const sortedLessons = response.data.sort((a, b) => Number(a.lessonId) - Number(b.lessonId));
        const lessonsWithVideos = await Promise.all(
          sortedLessons.map(async (lesson) => {
            const videosData = await fetchVideos(lesson.lessonId);
            return { ...lesson, videos: videosData};
          })
        );
        setLessonData(lessonsWithVideos);
        
      } else {
        setLessonData([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (id && user.id) {
      fetchLessonsAndVideos(id);
      getUserProgressByCampaignId(id, user.id);
  
    }
  }, [id, user.id]);
  

  const fetchVideos = async (lessonId) => {
    return new Promise((resolve) => {
      videos.getAllVideosByLesson(lessonId, async (response) => {
        if (response.status === "success" && Array.isArray(response.data)) {
          // const videosWithProgress = await Promise.all(response.data.map(async (video) => {
          //   getVideoProgress(user.id, lessonId.toString(), video.id);
          // }));
          resolve(response.data);
        } else {
          resolve([]);
        }
      });
    });
  };

  // const getVideoProgress = async (userId, lessonId, videoId) => {
  //   try {
  //     const response = await videos.getVideoDuration(userId, lessonId); 
  

  //   } catch (error) {
  //     console.error("Failed to get video progress:", error);
  //   }
  // };
  
  // const getVideoProgress = (userId, lessonId, videoId) => {
  //   return new Promise((resolve, reject) => {
  //     videos.getVideoDuration(userId, lessonId, (response) => {
  //       if (response.status === "success") {
  //         const savedDuration = response.data[0]?.duration || 0;
  //         resolve(savedDuration);
  //       } else {
  //         reject(new Error("Failed to get video progress"));
  //       }
  //     });
  //   });
  // };



  // const getVideoDuration = (userId, lessonId) => {
  //   
  //   videos.getVideoDuration(userId, lessonId, (response) => {
  //     if (response.status === "success") {
  //       const savedDuration = response.data[0]?.duration || 0;
  //       setDuration(savedDuration);
  //       if (!isNaN(savedDuration) && videoRef.current) {
  //         videoRef.current.currentTime = savedDuration;
  //       }
  //     }
  //     setLoading(false);
  //   });
  // };
  
  
  

  const getUserProgressByCampaignId = async (id, userId) => {
    userProgress.getAllUserProgressByCampaignId(id, userId, (response) => {
      setUserProgressData(response.status === "success" ? response.data : []);
      console.log('getAllUserProgressByCampaignId',response.data)
      
    });
  };

  const getUserProgressData = async (lesson) => {
    try {
      const response = await new Promise((resolve, reject) => {
        userProgress.getAllUserProgressByCampaignId(lesson.campaignId, user.id, (result) => {
          if (result.status === 'success') {
            const filteredProgress = result.data.filter(
              (progress) => progress.lesson_id === lesson.lessonId
            );
            console.log('filteredProgress',filteredProgress)
  
            if (filteredProgress.length > 0) {
              setUserProgressToSent(filteredProgress[0]); // ✅ Save matched progress
              resolve(filteredProgress[0]); // ✅ Return matched progress
            } else {
              reject(new Error("No progress found for this lesson"));
            }
          } else {
            reject(new Error("Failed to fetch user progress"));
          }
        });
      });
  
      return response; // ✅ Return resolved value
    } catch (error) {
      console.error("Error fetching user progress:", error);
      throw error; // ✅ Rethrow for caller to handle
    }
  };
  

  

  const getQuizStatus = (lesson) => {
    

    const progress = UserProgressData.find((data) => data.lesson_id === lesson.lessonId);
    
    return progress?.quiz_status === 1 ? "completed" : "pending";
  };

  const getVideoStatus = (lesson, videoId) => {
    

    const progress = UserProgressData.find(
      (data) => data.lesson_id === lesson.lessonId && data.video_id === videoId
    );
    

    return progress?.is_watched === 1 ? "completed" : "pending";
  };

  const getQuizScore = (lesson) => {
    const quiz = UserProgressData.find((data) => data.lesson_id === lesson.lessonId);
    
    return quiz && quiz.quiz_score !== undefined ? quiz.quiz_score : 'und';
  };

  // const getVideoDuration = async (lessonId) => {
  //   return new Promise((resolve, reject) => {
  //     videos.getVideoDuration(user.id, lessonId.lessonId, (response) => {
  //       if (response.status === "success") {
  //         const savedDuration = response.data[0]?.duration || 0;
  //         setDuration(savedDuration);
  //         
  //         resolve(savedDuration); // ✅ Return the data properly
  //       } else {
  //         reject("Failed to fetch duration");
  //       }
  //     });
  //   });
  // };
  

  const handleQuizClick = (lessonId) => {
    navigate(`/quiz/${lessonId}`, { state: { campaignId: id , data : UserProgressToSent} });
    
  };

  
  const handleVideoNavigate = async (video, lesson) => {
    if (!video) {
      console.error("Error: video is undefined!");
      return;
    }
  
    try {
      // Ensure UserProgressToSent is available before navigating
      let progressData = UserProgressToSent;
      if (!progressData) {
        progressData = await getUserProgressData(lesson);
        setUserProgressToSent(progressData); // Save it for future use
        
      }
  
      navigate(`/video/${lesson.lessonId}`, { 
        state: { 
          individualvdoData: video, 
          data: progressData, 
          campaignId: id 
        } 
      });
    } catch (error) {
      console.error("Error fetching user progress:", error);
    }
  };

  const handleStartClick = (lessonId) => {
    setStartedLessons((prev) => ({ ...prev, [lessonId]: true }));
    setExpandedLesson(lessonId); // Auto-expand when starting a lesson
    addUserProgress(lessonId);
  };

  const isLessonStartedOrCompleted = (lesson) => {
    const isAnyVideoCompleted = lesson.videos.some(
      (video) => getVideoStatus(lesson, video.id) === "completed"
    );
  
    return (
      startedLessons[lesson.lessonId] || 
      getQuizStatus(lesson) === "completed" || 
      isAnyVideoCompleted ||
      videoDurations[lesson.lessonId] > 0
    );
  };
  

  const addUserProgress = (lessonId,lessonCount) => {
    let data = {

      // batchId:myBatch,
      // campaign_id:id,
      // completed: 0,
      // // duration : 0,
      // // id: id,
      // is_deleted : user.is_deleted,
      // // is_watched :0,
      // lesson_id : lessonId,
      // // quiz_score : 0,
      // quiz_status : 0,
      // user_id : user.id
      // // uv_id : uv_id,
      // // video_id

      lessonId: lessonId,
      userId: user.id,
      campaignId: id,
      videoStatus: false,
      quizStatus: false,
      policyStatus: false,
      startDate: new Date(),
      endDate: new Date(),
      batchId: myBatch,
      quiz_status: 0,
    };
    setUserProgressToSent(data);
    

    

    userProgress.addUserProgress(data, (response) => {
      if (response.status === 'success') {
        // setUserProgressData(response.data);
        // Swal.fire({
        //   icon: "success",
        //   title: "User Progress Saved.",
        //   position: "top-end",
        //   toast: true,
        //   timer: 3000,
        //   showConfirmButton: false,
        //   customClass: { popup: "swal-popup" },
        //   didOpen: () => {
        //     document.querySelector(".swal-popup").style.marginTop = "60px";
        //   },
        // });
        console.log('userProgress has been Added');
        
        if (lessonCount == 0) {
          // getUserProgressByCampaignId(id, user?.id, lessonData);
        }
      } else {
        setUserProgressData([]);
      }
    });
  };


  
  return (
    <Box sx={{ border: "1px solid #ddd", borderRadius: "8px", padding: "16px", backgroundColor: "#fff" }}>
      <Typography variant="h5" gutterBottom color="black">
        Training Course
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ marginBottom: "20px" }}>
        This course covers fundamental concepts with engaging video content and interactive quizzes.
      </Typography>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", padding: "16px" }}>
          <CircularProgress />
        </Box>
      ) : lessonData.length > 0 ? (
        lessonData.map((lesson, lIndex) => (
          <Box key={lIndex} sx={{ marginBottom: "16px", border: "1px solid #ddd", borderRadius: "8px", padding: "8px" }}>
            <Accordion
              expanded={expandedLesson === lesson.lessonId}
              onChange={() => setExpandedLesson(expandedLesson === lesson.lessonId ? null : lesson.lessonId)}
            >
              <AccordionSummary
                expandIcon={isLessonStartedOrCompleted(lesson,lIndex) ? <ExpandMoreIcon /> : null} // Show only if started or completed
              >
                <Typography color="black">{lesson.lessonName}</Typography>

                  {!isLessonStartedOrCompleted(lesson) && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleStartClick(lesson.lessonId);
                      }}
                      sx={{ marginLeft: "auto" }}
                    >
                      Start
                    </Button>
                  )}

              </AccordionSummary>

              {isLessonStartedOrCompleted(lesson, lIndex) && (
                <AccordionDetails >
                  <Typography variant="body2" color="text.secondary" sx={{ marginBottom: "10px" }}>
                    {lesson.description || "No description available."}
                  </Typography>
                  <List>
                    {lesson.videos.map((video, vIndex) => (
                      <ListItem
                        key={vIndex}
                        onClick={() => handleVideoNavigate(video, lesson)}
                        sx={{
                          padding: "8px 16px",
                          "&:hover": { backgroundColor: "#f1f1f1" },
                        }}
                      >
                        <ListItemIcon>
                          { getVideoStatus(lesson, video.id) === "completed" &&  videoDurations[lesson.lessonId] >= 900? (
                            <CheckCircleIcon sx={{ color: "#2e7d32" }} /> // for 03 HTML lesson both are not showing cause \is_watchhd is true but not whched 90% above that backednd issue and can be solver when logging as a new user 
                          ) : (
                            <CheckCircleIcon color="disabled" />
                          )}
                        </ListItemIcon>

                        <ListItemText primary={video.title} secondary={`Video • ${video.duration}`} />

                        {getVideoStatus(lesson, video.id) === "pending" &&  videoDurations[lesson.lessonId] >0? (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                              // startIcon={<PlayArrow />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleVideoNavigate(video, lesson);
                            }}
                          >
                            Resume
                          </Button>
                        ) : getVideoStatus(lesson, video.id) === "completed" && videoDurations[lesson.lessonId] >= 900? (
                          <Typography variant="body2" sx={{ color: '#2E7D32'}}>
                            Completed
                          </Typography>
                        ) : '' }
                      </ListItem>

                    ))}
                    <ListItem
                      onClick={() => handleQuizClick(lesson.lessonId)}

                      sx={{
                        // backgroundColor: getQuizStatus(lesson) === "completed" ? "#e6f7e9" : "#f9f9f9",
                        padding: "0px 16px",
                        borderRadius: "8px",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        "&:hover": { backgroundColor: "#f1f1f1" }
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ListItemIcon>
                          {getQuizStatus(lesson) === "completed" ? <CheckCircleIcon sx={{ color: "#2e7d32" }} /> : <CheckCircleIcon color="disabled" />}
                        </ListItemIcon>
                        <ListItemText primary="Quiz" secondary={getQuizStatus(lesson) === "completed" ? `🎯 You Scored • ${getQuizScore(lesson)}` : "Not Yet Attempted"} />
                        {/* <ListItemText primary="Quiz" secondary={getQuizStatus(lesson) === "completed" ? `🎯 You Scored • ${getQuizScore(lesson)}/${lesson.maxQuestions}` : "Not Yet Attempted"} /> */}

                      </Box>
                    </ListItem>
                  </List>
                </AccordionDetails>  

              )}
            </Accordion>
          </Box>
        ))
      ) : (
        <Typography sx={{ padding: "16px" }}>No lessons available.</Typography>
      )}
    </Box>
  );
};

export default CourseOverview;
