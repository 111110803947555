import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Box, Typography, CircularProgress, Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import questions from "modules/questions";
import userProgress from "modules/userProgress";
import userAnswers from "modules/userAnswers";
import HTMLRenderer from "react-html-renderer";

const QuizPage = () => {
  const { lessonId } = useParams();
  const location = useLocation();
  const campaignId = location.state?.campaignId || null;
  const UserProgress = location.state?.data || null;

  
  const [questionsData, setQuestionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userProgressData, setUserProgressData] = useState([]);
  const [userAnswersData, setUserAnswersData] = useState([]);
  const [QuizScore, setQuizScore] = useState(null);
  const { user } = useSelector(({ login }) => login);
  const [QuizStatus,setQuizStatus] = useState(0);

  useEffect(() => {
    if (lessonId && user?.id) {
      fetchUserProgress(user.id, lessonId);
    }
  }, [lessonId, user?.id]);

  const fetchUserProgress = async (userId, lessonId) => {
    if (!campaignId) return;

    userProgress.getAllUserProgressByCampaignId(campaignId, userId, (response) => {
      if (response.status === "success") {
        setUserProgressData(response.data);
        
        const currentQuiz = response.data.find((progress) => progress.lesson_id == lessonId);
        fetchAllQuestions(lessonId);
        if (currentQuiz?.quiz_status) {
          fetchUserAnswers(userId, lessonId);
        }
      } else {
        console.error("API Error fetching user progress");
      }
    });
  };

  const fetchAllQuestions = async (id) => {
    if (!id) return;
    setLoading(true);

    questions.getAllQuestionsAndOptionsByLesson(id, (response) => {
      setLoading(false);
      if (response.status === "success") {
        setQuestionsData(response.data);
      } else {
        setQuestionsData([]);
        console.error("API Error fetching questions");
      }
    });
  };

  const fetchUserAnswers = async (userId, lessonId) => {
    if (!userId || !lessonId) return;

    questions.getAnswersByLessonId(userId, lessonId, (response) => {
      if (response.status === "success") {
        setUserAnswersData(response.data);
      } else {
        console.error("API Error fetching user answers");
      }
    });
  };

  const currentQuiz = userProgressData.find((progress) => progress.lesson_id == lessonId) || {};
  const isQuizCompleted = currentQuiz.quiz_status;



  const handleOptionChange = (value) => {
    setSelectedAnswer(value);
  };

  const handleSubmitAndNext = async () => {
    if (!user?.id) {
      Swal.fire({
        icon: "error",
        title: "User not authenticated.",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
        customClass: { popup: "swal-popup" },
        didOpen: () => {
          document.querySelector(".swal-popup").style.marginTop = "60px";
        },
      });
      return;
    }
  
    const currentQuestion = questionsData[currentQuestionIndex];
    if (!currentQuestion) return;
  
    const selectedOption = currentQuestion.options.find((option) => option.text === selectedAnswer);
  
    if (!selectedOption) {
      Swal.fire({
        icon: "warning",
        title: "Please select an answer before proceeding.",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
        customClass: { popup: "swal-popup" },
        didOpen: () => {
          document.querySelector(".swal-popup").style.marginTop = "60px";
        },
      });
      return;
    }
  
    const answerToSubmit = {
      userId: user?.id,
      answerId: selectedOption.id,
      questionId: currentQuestion.id,
      lessonId: Number(lessonId),
    };
  
  
    try {
        userAnswers.addAnswers(answerToSubmit, (res) => {
          if (res.data?.status === "Unique Constraint Error") {
            shouldUpdateProgress = false;
            Swal.fire({
              icon: "warning",
              title: "You have already submitted this answer.",
              position: "top-end",
              toast: true,
              timer: 3000,
              showConfirmButton: false,
              customClass: { popup: "swal-popup" },
              didOpen: () => {
                document.querySelector(".swal-popup").style.marginTop = "60px";
              },
            });
          }
        });
    } catch (error) {
      console.error("Error submitting answer:", error);
    }
    
    let shouldUpdateProgress = true;
  
    // ✅ Only update progress if the answer is NOT already submitted
    if (shouldUpdateProgress) {
      updateProgress();
    }
  
    // ✅ Reset selected answer
    setSelectedAnswer(null);
  
    // ✅ Move to next question
    if (currentQuestionIndex + 1 < questionsData.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      Swal.fire({
        icon: "success",
        title: "Quiz completed!",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
        customClass: { popup: "swal-popup" },
        didOpen: () => {
          document.querySelector(".swal-popup").style.marginTop = "60px";
        },
      });
  
      fetchUserProgress(user.id, lessonId);
    }
  };
  


  const updateProgress = () => {
    let upData = userProgressData.find((u) => u.lesson_id == lessonId);
    
    if (upData) {
      const isCorrect = questionsData[currentQuestionIndex]?.options.some(
        (option) => option.text === selectedAnswer && option.isAnswer
      );

      if (isCorrect) {
        upData.quiz_score = (upData.quiz_score || 0) + 1;
        setQuizScore(upData.quizScore);
      } 
      if (currentQuestionIndex + 1 === questionsData.length) {
         upData.quiz_status = 1;
      }
 
        // if (currentQuestionIndex + 1 === questionsData.length || upData.quiz_status == 1 ) {
        //   setQuizStatus(1);
        //   
        // } else{
        //   setQuizStatus(0);
        // }
        
      upData.batchId = campaignId;
      updateUserProgress(upData.id, upData);

    }
  };

  const updateUserProgress = (id, data) => {
    

    userProgress.updateUserProgressById(id, data, (response) => {
      
    
      if (response.status !== "success") {
        console.error("Error updating user progress");
      } 
      if (response.data === 'success') {

        Swal.fire({
          icon: "success",
          title: "User Progress Updated",
          position: "top-end",
          toast: true,
          timer: 3000,
          showConfirmButton: false,
          customClass: { popup: "swal-popup" },
          didOpen: () => {
            document.querySelector(".swal-popup").style.marginTop = "60px";
          },
        });

      }
    });
  };

  const stripHtml = (html) => {
    if (!html) return "";
    const doc = new DOMParser().parseFromString(html, "text/html");
    
    return doc.body.textContent || "";
  };
  

  return (
    <Box sx={{ padding: "16px" }}>
      <Typography variant="h6">Practice Quiz</Typography>

      <Box sx={{ backgroundColor: "#f9f9f9", borderRadius: "8px", padding: "16px", marginTop: "16px" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <CircularProgress />
          </Box>
        )  : questionsData.length === 0 ? (
          <Typography variant="subtitle1" sx={{ color: "gray", textAlign: "center", padding: "20px" }}>
            No questions available for this quiz.
          </Typography>
        ) : isQuizCompleted? (
          questionsData.map((question, qIndex) => (
            <Box key={qIndex} sx={{ marginBottom: "20px", padding: "10px", borderBottom: "1px solid #ddd" }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {qIndex + 1}. {stripHtml(questionsData[qIndex]?.text || "")}
            </Typography>


              <RadioGroup>
                {question.options.map((option, oIndex) => {
                  const isCorrect = option.isAnswer;
                  const userSelectedThis = userAnswersData?.some((ans) => ans.option_id === option.id);
                  const isWrong = userSelectedThis && !isCorrect;

                  return (
                    <FormControlLabel
                      key={oIndex}
                      value={option.text}
                      label={option.text}
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: isCorrect ? "green" : isWrong ? "red" : "default",
                            },
                          }}
                        />
                      }
                      checked={userSelectedThis || isCorrect}
                      disabled
                    />
                  );
                })}
              </RadioGroup>
            </Box>
          ))
        ) : (
          questionsData.length > 0 && (
            <>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {currentQuestionIndex + 1}. <HTMLRenderer html={questionsData[currentQuestionIndex]?.text || ""} />
              </Typography>



        <RadioGroup value={selectedAnswer || ""} onChange={(e) => handleOptionChange(e.target.value)}>
          {questionsData[currentQuestionIndex]?.options.map((option, oIndex) => (
            <FormControlLabel key={oIndex} value={option.text} label={option.text} control={<Radio />} />
          ))}
        </RadioGroup>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitAndNext}
          sx={{ marginTop: "20px" }}
          disabled={!selectedAnswer}
        >
          {currentQuestionIndex + 1 === questionsData.length ? "Finish Quiz" : "Next"}
        </Button>
      </>
          ) 
        )}
      </Box>
    </Box>
  );
};

export default QuizPage;
