import { useSelector } from 'react-redux';
import './userBatches.css';
import {
  Box,
  Typography,
  Grid,
  Stack,
  Tab,
  Chip,
  Divider,
  Paper,
  Button,
  LinearProgress,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DoneIcon from '@mui/icons-material/Done';
import React, { useEffect, useState } from 'react';
import campaigns from 'modules/campaigns';
import UserProgress from 'app/mmEsoft/module/UserProgress';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';

function UserBatches() {
  const { campaignList } = useSelector(({ campaignsReducer }) => campaignsReducer);
  const { user } = useSelector(({ login }) => login);
  const [lessonData, setLessonData] = useState([]);
  const [userprogress, setUserprogress] = useState([]);
  const [value, setValue] = useState('1');
  const [showDetails, setShowDetails] = useState(false);
  const [videoPercentage, setvideoPercentage] = useState(0);
  const [QuizPercentage, setQuizPercentage] = useState(0);

  

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShowDetails(false);
  };

  const getCampaignLessons = (id) => {
    campaigns.getLessonsByCampaignId(id, (response) => {
      if (response.status === 'success') {
        setLessonData(response?.data);
      } else {
        console.log('Error while loading lessons');
      }
    });
  };

  const getUserProgress = (id) => {
    UserProgress.getUserProgress(id, user.id, (response) => {
      if (response.status === 'success') {
        setUserprogress(response?.data);
        //Calculating Video Watched Percentage
        const completedVideos = response.data.filter((item) => item.videoStatus).length;
        const totalVideos = response.data.length;
        setvideoPercentage(totalVideos > 0 ? (completedVideos / totalVideos) * 100 : 0);
        //Calculating Quiz Watched Percentage
        const completedQuizes = response.data.filter((item) => item.quizStatus).length;
        const totalQuizes = response.data.length;
        setQuizPercentage(totalVideos > 0 ? (completedQuizes / totalQuizes) * 100 : 0);
        
      } else {
        console.log('Error while getting user progress');
      }
    });
  };

  const handleTabClick = (id) => {
    getCampaignLessons(id);
    getUserProgress(id);
    setShowDetails(false);
  };

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} sx={{ bgcolor: 'white', borderRadius: 2, p: 2, boxShadow: 2 }}>
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} variant='scrollable' scrollButtons='auto'>
                {campaignList.map((item) => (
                  <Tab key={item.id} label={item.title} value={item.id.toString()} onClick={() => handleTabClick(item.id)} />
                ))}
              </TabList>
            </Box>

            {!showDetails ? (
              <TabPanel value={value}>
              <Paper
                sx={{
                  bgcolor: 'grey.100',
                  p: 3,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {/* Keeping progress bars in a row */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: 2,
                  }}
                >
                  <Box sx={{ padding:'5px',display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant='h6' fontWeight={500}>Video Progress</Typography>
                    <LinearProgress variant='determinate' value={videoPercentage} sx={{ height: 10, width: '200px', borderRadius: 5 }} />
                    <Typography variant='body1'>{videoPercentage.toFixed(2)}% Videos Completed</Typography>
                  </Box>
                  
                  <Box sx={{ padding:'5px', display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant='h6' fontWeight={500}>Quiz Progress</Typography>
                    <LinearProgress variant='determinate' value={QuizPercentage} sx={{ height: 10, width: '200px', borderRadius: 5 }} />
                    <Typography variant='body1'>{QuizPercentage.toFixed(2)}% Quiz Completed</Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'start', width: '100%', mt: 2 }}>
                  <Button variant='contained' color='primary' onClick={() => setShowDetails(true)}>
                    View More
                  </Button>
                </Box>
              </Paper>
            </TabPanel>

            ) : (
              lessonData.map((campaignItem) => (
                <TabPanel key={campaignItem.lessonId} value={campaignItem.campaignId.toString()}>
                  <Paper sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 2 }}>
                    <Typography variant='h6' fontWeight={500} gutterBottom>
                      {campaignItem.lessonName}
                    </Typography>
                    <Typography variant='body1' color='text.secondary' gutterBottom>
                      {campaignItem.lessonDescription}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Stack spacing={2}>
                      {userprogress.map((data) => {
                        if (data.lessonId === campaignItem.lessonId) {
                          return (
                            <Stack key={data.lessonId} direction='row' alignItems='center' spacing={3}>
                              <Typography variant='body1'>
                                Quiz Score: <strong>{data.quizScore}</strong> / {campaignItem.maxQuestions}
                              </Typography>
                              <Chip
                                label='Video Watched'
                                icon={<DoneIcon color={data.videoStatus ? 'success' : 'disabled'} />}
                                sx={{
                                  bgcolor: data.videoStatus ? 'green.100' : 'grey.300',
                                  color: data.videoStatus ? 'green.800' : 'grey.800',
                                  fontWeight: 500,
                                }}
                              />
                            </Stack>
                          );
                        }
                        return null;
                      })}
                    </Stack>
                  </Paper>
                </TabPanel>
              ))
            )}
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
}

export default UserBatches;