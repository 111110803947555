import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // Import the CSS for styling
import './ckEditor.css';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';

let Editor = () => <React.Fragment />;
const AddTemplate = props => {
  const contentRef = useRef(null);

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const editorDataa = () => {
    Editor = require('react-draft-wysiwyg').Editor;
    if (props.data?.text) {
      const blocksFromHTML = convertFromHTML(props.data?.text);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(content));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  };

  React.useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
    editorDataa();
  }, []);
  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title1: yup.string().when('title', {
        is: title => !title || title.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
    },
    ['title', 'title1']
  );

  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close}>
        <Formik
          validateOnChange={true}
          initialValues={{
            title: props?.data?.title ? props?.data?.title : '',
            title1: props?.data?.title1 ? props?.data?.title1 : '',
            text: props?.data?.text ? props?.data?.text : '',
            subject: props?.data?.subject ? props?.data?.subject : '',
            type: props?.data?.type ? props?.data?.type : '',
            language: props?.data?.language ? props?.data?.language : '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            
            props.addEmailTemplate(data);
            props.close();
          }}
        >
          {({ setFieldValue }) => (
            <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
              <DialogContent
                ref={contentRef}
                sx={{ overflowY: 'auto', maxHeight: '80vh' }}
              >
                <DialogTitle id="alert-dialog-title" textAlign="center">
                  Add Email Template
                </DialogTitle>
                <Box
                  sx={{
                    width: '500px',
                  }}
                >
                  <Div sx={{ mb: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="title"
                      label="Title(English)*"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="title1"
                      label="Title ( العربيه )"
                    />
                  </Div>
                  <Div sx={{ flex: 1 }}>
                    <Editor
                      editorStyle={{
                        width: '100%',
                        minHeight: 80,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'lightgray',
                      }}
                      editorState={editorState}
                      name="text"
                      onEditorStateChange={editorState => {
                        setEditorState(editorState);
                        let text = draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                        );
                        setFieldValue('text', text);
                      }}
                    />
                  </Div>

                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField fullWidth name="subject" label="Subject" />
                  </Div>
                  {/* <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      select
                      name="type"
                      label="Choose Type"
                    >
                      <MenuItem key="Phishing" value="phishing">
                        Phishing
                      </MenuItem>
                      <MenuItem key="General" value="general">
                        General
                      </MenuItem>
                      <MenuItem key="Tip" value="tip">
                        Tip
                      </MenuItem>
                      <MenuItem key="Training" value="training">
                        Training
                      </MenuItem>
                      <MenuItem key="Frame" value="frame">
                        Frame
                      </MenuItem>
                      <MenuItem key="Certificate" value="certificate">
                        Certificate
                      </MenuItem>
                      <MenuItem
                        key="Certificate Notification"
                        value="certificate_notification"
                      >
                        Certificate Notification
                      </MenuItem>
                    </JumboTextField>
                  </Div> */}
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      select
                      fullWidth
                      name="language"
                      label="Language"
                    >
                      <MenuItem key="English" value="English">
                        English
                      </MenuItem>
                      <MenuItem key="العربيه" value="العربيه">
                        العربيه
                      </MenuItem>
                    </JumboTextField>
                  </Div>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" type="submit" autoFocus>
                  Save
                </Button>
                <Button variant="contained" onClick={props.close}>
                  {' '}
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddTemplate;
