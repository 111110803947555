import React from 'react';
import Div from '@jumbo/shared/Div';
import Link from '@mui/material/Link';
import Group260 from '../../../Assets/Group260.png';
import { useSelector } from 'react-redux';
import { logoUrl } from 'modules/appApi';
import logo from './projectIcon.png';
import ExcendraLogo from './projectIcon2.png'
const Logo = ({ mini, mode, sx }) => {
  const { user } = useSelector(({ login }) => login);

  return (
    <Div sx={{ display: 'inline-flex', ...sx }}>
      <Link
        href={user?.roleId === 1 ? '/dashboards/dashboard' : '/dashboards/user'}
      >
        {!mini ? (
          <img
            // src={mode === 'light' ? `${Group260}` : `${Group260}`}
            // src={logoUrl}
            // src={logo}
            src={ExcendraLogo}
            alt='eSoft'
            // width={120}
            // height={120}
            width={100}
            height={100}
            
          />
        ) : (
          <img
            width={150}
            height={150}
            // src={
            //   mode === 'light'
            //     ? `${Group260}/logo-short.png`
            //     : `${Group260}/logo-short-white.png`
            // }
            // src={logoUrl}
            src={logo}
            alt='eSoft'
          />
        )}
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: 'light',
};

export default Logo;
