import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';

const userMenus = [
  {
    label: 'sidebar.menu.home',
    type: 'section',
    children: [
      {
        uri: '/dashboards/user',
        label: 'Dashboard',
        type: 'nav-item',
        icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },

  {
    uri: '/courses/:id',
    label: 'My Courses',
    type: 'collapsible',
    icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />,
    // children: [
    //   {
    //     uri: '/campaigns/:id',
    //     label: 'Campaign',
    //     type: 'nav-item',
    //   },
    // ],
  },

  {
    uri: '/user-assignments',
    label: 'Assignments',
    type: 'nav-item',
    icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />,
  },

  {
    uri: '/my-result',
    label: 'Result',
    type: 'nav-item',
    icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />,
  },

  {
    uri: '/user-certificates',
    label: 'My Certificates',
    type: 'nav-item',
    icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />,
  },

  // {
  //   uri: "/list-views/projects",
  //   label: "sidebar.menuItem.projects",
  //   type: "nav-item",
  //   icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />,
  // },

  {
    uri: '/my-library',
    label: 'My Library',
    type: 'nav-item',
    icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />,
  },
];
export default userMenus;
