import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Paper, CircularProgress, Button } from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import videos from "modules/videos";
import userVideos from "modules/userVideos";
import ReactPlayer from 'react-player';

export default function UserLesson() {
  const { lessonId } = useParams();
  const [videoData, setVideoData] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [fetchingURL, setFetchingURL] = useState(false);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [duration, setDuration] = useState(0);
  const playerRef = useRef(null);
  const { user } = useSelector(({ login }) => login);
  const location = useLocation();
  const oneVideoData = location.state?.individualvdoData;
  const UserProgressData = location.state?.data;
  const BatchId = location.state?.campaignId;
  const lastUpdatedTime = useRef(0);
  const [watchedPercent, setWatchedPercent] = useState(0);
  const [userVideoId, setUserVideoId] = useState();
  const [totalDuration, setTotalDuration] = useState(0);

  // ✅ Fetch video data when component loads
  useEffect(() => {
    if (oneVideoData) {
      setVideoData(oneVideoData);
      getVideoURL(oneVideoData.url);

      if (UserProgressData?.is_watched === 1) {
        setIsVideoWatched(true);
      }
    }
  }, [oneVideoData]);

  // ✅ Set total duration after the player is ready
  const handleOnReady = () => {
    const duration = playerRef.current?.getDuration();
    if (duration && !isNaN(duration) && duration > 0) {
      setTotalDuration(duration);
      console.log(`Video duration set: ${duration} seconds`);
  
      // ⏳ Delay seeking for smoother playback
      setTimeout(() => {
        getVideoDuration(user.id, oneVideoData.lessonId);
      }, 1000);
    }
  };
  

  // ✅ Get video URL from API
  const getVideoURL = (videoName) => {
    setFetchingURL(true);
    videos.getVideoURL(videoName, (response) => {
      if (response.status === "success") {
        setVideoSrc(response.data.url);
      } else {
        setVideoSrc(null);
      }
      setFetchingURL(false);
    });
  };

  const hasSeeked = useRef(false);

  const getVideoDuration = (userId, lessonId) => {
    videos.getVideoDuration(userId, lessonId, (response) => {
      if (response.status === "success") {
        const savedDuration = response.data[0]?.duration || 0;
        setDuration(savedDuration);
        setUserVideoId(response.data[0]?.id);
  
        if (savedDuration > 0 && !hasSeeked.current && playerRef.current) {
          hasSeeked.current = true; // ✅ Prevent multiple seeks
          playerRef.current.seekTo(savedDuration, "seconds");
          console.log(`Seeking to: ${savedDuration} seconds`);
        }
      }
    });
  };
  

  // ✅ Track video progress and update state
  const lastProgressUpdate = useRef(0);

  const onProgress = ({ playedSeconds }) => {
    const playedTime = Math.floor(playedSeconds);
  
    if (playedTime === duration || playedTime === lastProgressUpdate.current) return;
    
    lastProgressUpdate.current = playedTime; // ✅ Prevent redundant updates
  
    if (totalDuration > 0) {
      const watchedPercentage = ((playedTime / totalDuration) * 100).toFixed(2);
      setWatchedPercent(watchedPercentage);
  
      if (playedTime - lastUpdatedTime.current >= 30) { //update duration on every 30's
        lastUpdatedTime.current = playedTime;
        
        if (playedTime > duration) {
          setDuration(playedTime);
          updateUserProgress(userVideoId, { duration: playedTime });
        }
      }
      if (!isVideoWatched && Number(watchedPercentage) >= 90) {
        

        markVideoAsWatched();

      }
    }
  };
  

  // ✅ Mark video as watched
  const markVideoAsWatched = async () => {
    if (!isVideoWatched) {
      const data = {
        videoId: videoData.id,
        userId: user.id,
        duration,
        lessonId,
        isWatched: true,
      };

      await addUserVideos(data);
    }
  };

  // ✅ Save user progress
  const addUserVideos = async (data) => {
    userVideos.addUserVideos(data, async (response) => {
      if (response.status === "success") {
        updateProgress(UserProgressData);
      }
    });
  };

  const updateProgress = (UserProgressData) => {
    

    if (!UserProgressData?.videoStatus) {
      const progressUpdate = {
        ...UserProgressData,
        videoStatus: true,
        batchId: BatchId,
      };

      updateUserProgress(userVideoId, progressUpdate);
    }
  };

  const updateUserProgress = (id, data) => {
    userVideos.updateUserVideosById(id, data, (response) => {
      if (response.status !== "success") {
        console.error("Error updating progress");
      }
    });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          textAlign: "center",
          width: "100%",
          height: "600px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" fontWeight="bold" color="primary" mb={2}>
          🎥 {videoData ? videoData.title : "-"}
        </Typography>

        <Box
          sx={{
            borderRadius: "12px",
            overflow: "hidden",
            width: "100%",
            height: "600px",
            backgroundColor: "#000",
          }}
        >
          {fetchingURL ? (
            <CircularProgress color="primary" />
          ) : videoSrc ? (
            <ReactPlayer
              ref={playerRef}
              url={videoSrc}
              width="100%"
              height="100%"
              controls
              playing
              onReady={handleOnReady}
              onProgress={onProgress}
              onEnded={markVideoAsWatched}
            />
          ) : (
            <Typography variant="h6" color="error">
              ❌ Video Not Available
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
