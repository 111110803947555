import React, { useEffect } from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import videos from 'modules/videos';
import userVideos from 'modules/userVideos';
import { useSelector } from 'react-redux';

const UserLessonDetailss = props => {
  const { user } = useSelector(({ login }) => login);
  const [userVideoData, setUserVideoData] = React.useState();
  const [_selectedListItem, _setSelectedListItem] = React.useState(
    props.selectedListItem
  );
  useEffect(() => {
    _setSelectedListItem(props.selectedListItem);
  }, [props.selectedListItem]);

  useEffect(() => {
    getAllVideosByLesson(props?.lesson?.lessonId);
  }, [props?.lesson?.lessonId]);

  const getAllVideosByLesson = id => {
    videos.getAllVideosByLesson(id, response => {
      if (response.status === 'success') {
        let allVideoDataa = response.data;
        getUserVideoByLessonId(props?.lesson?.lessonId, allVideoDataa);
      }
    });
  };

  const getUserVideoByLessonId = (id, allVideos) => {
    let shouldHandleQuiz = true; // Flag to track whether to handle quiz

    userVideos.getUserVideoByLessonIdAndUserId(id, user.id, response => {
      if (response.status === 'success' && response.data?.length > 0) {
        let _userVideoData = response.data;
        // Track if any video is unwatched
        let hasUnwatchedVideo = false;

        for (let i = 0; i < allVideos.length; i++) {
          const video = allVideos[i];
          let userVideo = _userVideoData.find(v => v.videoId == video?.id);
          
          if (userVideo) {
            video.userVideoData = userVideo;
            if (
              !_selectedListItem &&
              !video.userVideoData.isWatched &&
              !props?.lesson?.showVideoWatched
            ) {
              handleVideoClick(video);
              shouldHandleQuiz = false; // Set the flag to false if video conditions are met
              break; // Break out of the loop as soon as the first unwatched video is found
            } else if (!video.userVideoData.isWatched) {
              hasUnwatchedVideo = true; // Set the flag to true if any video is unwatched
            }
          } else if (!_selectedListItem) {
            handleVideoClick(video);
            shouldHandleQuiz = false; // Set the flag to false if video conditions are met
            break; // Break out of the loop as soon as the first unwatched video is found
          }
        }

        // If the flag is still true and there are no unwatched videos, handle the quiz
        if (
          shouldHandleQuiz &&
          !hasUnwatchedVideo &&
          !props?.lesson?.showQuizScore
        ) {
          handleQuizClick();
        }

        setUserVideoData(allVideos);
      } else {
        setUserVideoData(allVideos);
        if (!_selectedListItem) {
          handleVideoClick(allVideos[0]);
        }
      }
    });
  };

  const handleVideoClick = clickedVideo => {
    props?.setSelectedListItem(`v_${clickedVideo?.id}`);
    props?.handleListItemClickCallBack(1, props?.lesson, clickedVideo);
  };

  const handleQuizClick = () => {
    props?.setSelectedListItem(`q_${props?.lesson?.lessonId}`);
    props?.handleListItemClickCallBack(2, props?.lesson);
  };

  return (
    <List component='nav' aria-label='main mailbox folders'>
      {userVideoData &&
        userVideoData?.map(v => (
          <ListItemButton
            selected={
              _selectedListItem === `v_${v.id}` && props?.selectedIndex === 1
            }
            onClick={() => {
              handleVideoClick(v);
            }}
          >
            <ListItemIcon>
              <PlayArrowIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant='h6' fontSize='17px'>
                  {v.title ? v.title : v.title1}
                </Typography>
              }
              sx={{ fontSize: '20px' }}
            />
          </ListItemButton>
        ))}
      <ListItemButton
        selected={
          _selectedListItem === `q_${props?.lesson?.lessonId}` &&
          props?.selectedIndex === 2
        }
        onClick={() => {
          handleQuizClick();
        }}
      >
        <ListItemIcon>
          <ListAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant='h6' fontSize='17px' mt='4px'>
              Quiz
            </Typography>
          }
        />
      </ListItemButton>
    </List>
  );
};

export default UserLessonDetailss;
