import React from 'react';

import Page from '@jumbo/shared/Page';
import ClientDashBoard from 'app/clientSide/clientDashboard/ClientDashBoard';
import UserCampaignss from 'app/clientSide/userCampaigns/UserCampaignss';
import LibraryDashboard from'app/clientSide/library/LibraryDashboard';
import UserBatches from 'app/mmEsoft/clientSide/userBatches/userBatches';
import UserAssignmentss from 'app/mmEsoft/clientSide/userAssignments/userAssignmentss';
import UserAssignmentsDetails from 'app/mmEsoft/clientSide/userAssignments/userAssignmentsDetails';
import UserCourses from 'app/clientSide/UserCourses/UserCourses';
import Quiz from 'app/clientSide/UserCourses/Quiz';
import UserLesson from 'app/clientSide/UserCourses/UserLesson';

const clientRoutes = [
  {
    path: '/dashboards/user',
    element: <Page component={ClientDashBoard} />,
  },
  {
    path: '/campaigns/:id',
    element: <Page component={UserCampaignss} />,
  },
  //Shows Perticular clicked Course from SideBar
  {
    path: '/courses/:id',
    element: <Page component={UserCourses} />,
  },
    //Shows Perticular clicked Quiz
  {
    path: '/quiz/:lessonId',
    element: <Page component={Quiz}/>,
  },
  //Shows Perticular clicked video
  {
    path: '/video/:lessonId',
    element: <Page component={UserLesson}/>,
  },
 
   
  {
    path:'/my-result',
    element: <Page component={UserBatches}  />,
  },   
  {
    path: '/user-assignments',  
    element: <Page component={UserAssignmentss} />,
  },

  {
    path: '/user-assignments/:id',   
    element: <Page component={UserAssignmentsDetails} />,
  },


  {
    path: '/my-library',
    element: <Page component={LibraryDashboard} />,
  }


];

export default clientRoutes;
