import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import videos from 'modules/videos';
import data from './myVideo/data.json';
import TextField from '@mui/material/TextField';

const interactiveVideoJson = {};
const AddVideos = props => {
  const Swal = useSwalWrapper();

  React.useEffect(() => {
    getAllVideosFromCloudOrFile();
  }, []);
  const [selectedVideo, setSelectedVideo] = useState('');

  const [existing, setExisting] = useState(0);
  const [cloudVideoData, setCloudVideoData] = useState(null);
  const [sample, setSample] = useState(false);
  const [jsonData, setJsonData] = useState(JSON.stringify(data, null, 2));
  const [isPropsDataAvailable, setIsPropsDataAvailable] = useState(false);
  const [newUrl, setNewUrl] = useState();
  const JSONPretty = require('react-json-pretty');
  var beautify = require('json-beautify');

  const theme = require('react-json-pretty/dist/monikai');
  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required.'),
      }),
      title1: yup.string().when('title', {
        is: title => !title || title.length === 0,
        then: yup.string().required('At least one of the fields is required.'),
      }),
    },
    ['title', 'title1']
  );

  useEffect(() => {
    if (props?.data?.interaction) {
      setJsonData(props?.data?.interaction, null, 2);
      setIsPropsDataAvailable(true);
    } else {
      setJsonData(jsonData);
      setIsPropsDataAvailable(false);
    }
  }, [props?.data?.interaction]);

  const handleJsonStringChange = event => {
    const newJsonString = event.target.value;
    setJsonData(newJsonString);
  };
  const handleVideoFileInputChange = event => {
    const file = event.target.files[0];

    // Store the video file in local storage
    const reader = new FileReader();
    reader.onload = function (e) {
      const videoData = e.target.result;
      // localStorage.setItem("selectedVideo", videoData);
      setSelectedVideo(file);
    };

    // Read the video file as a data URL
    reader.readAsDataURL(file);
    setExisting(2);
  };
  const uploadVideo = () => {
    const formData = new FormData();
    formData.append('file', selectedVideo);
    videos.uploadVideo(formData, response => {
      if (response.status === 'success') {
        props.getAllVideosByLesson(props.lessonId);
        Swal.fire({
          icon: 'success',
          title: 'Video has been uploaded successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while uploading video.',
        });
      }
    });
  };

  const getAllVideosFromCloudOrFile = () => {
    videos.getAllVideosFromCloudOrFile(response => {
      if (response.status === 'success') {
        setCloudVideoData(response.data?.videos);
      } else {
        setCloudVideoData([]);
      }
    });
  };

  const handleAddEditVideo = data => {
    data.url = data.url ? data.url : newUrl;
    if (props?.data?.id) {
      videos.updateVideo(props.data.id, data, response => {
        if (response.status === 'success') {
          props.getAllVideosByLesson(props.lessonId);
          Swal.fire({
            icon: 'success',
            title: 'Video has been updated successfully.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'There is an Error while updating video.',
          });
        }
        if (props.data.url != data.url) {
          uploadVideo();
        }
      });
    } else {
      props.addVideo(data);
      const foundVideo = cloudVideoData.find(item => item === data.url);
      if (!foundVideo) uploadVideo();
    }
    props.close();

    setSelectedVideo('');
  };
  const colors = {
    keys: 'blue',
    null: 'red',
  };

  const options = {
    noColor: true,
    maxDepth: 5,
    colors: colors,
  };

  // const handleDropdownChange = event => {
  //   setExisting(1);
  //   setSelectedVideo({ name: event.target.value });
  // };
  const handleDropdownChange = (event, setFieldValue) => {
    
    setFieldValue('url', event.target.value);
    setNewUrl(event.target.value);
  };


  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close} maxWidth="md" fullWidth>
        <DialogTitle id="alert-dialog-title" textAlign="center">
          {props?.data?.id ? 'Edit Video' : 'Add Video'}
        </DialogTitle>
        <Formik
          validateOnChange={true}
          initialValues={{
            title: props?.data?.title ? props.data?.title : '',
            title1: props?.data?.title1 ? props.data?.title1 : '',
            url: props?.data?.url && props.data?.url,
            interaction: props?.data?.interaction
              ? props?.data?.interaction
              : '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            data.lessonId = props?.lessonId;
            data.url = props?.data?.id ? props.data?.url : selectedVideo?.name;
            // data.interaction = jsonData;
            data.interaction = data.interaction ? data.interaction : '';
            handleAddEditVideo(data);
            props.close();
            setSelectedVideo('');
          }}
        >
          {({ values, setFieldValue }) => (
            <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
              <DialogContent>
                <Box
                  sx={{
                    width: '700px',
                  }}
                >
                  <Div sx={{ mb: 2, mt: 1, width: '650px' }}>
                    <JumboTextField
                      fullWidth
                      sx={{ width: '700px' }}
                      name="title"
                      label="Title(English)"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      sx={{ width: '700px' }}
                      name="title1"
                      label="Title ( العربيه )"
                    />
                  </Div>
                  {(existing == 1 || existing == 0) && (
                    <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                      <InputLabel htmlFor="video-select">
                        Select Video
                      </InputLabel>
                      <Select
                        fullWidth
                        label="Select Video"
                        // value={selectedVideo?.name}
                        value={values?.name}
                        //  onChange={handleDropdownChange}
                        onChange={event =>
                          handleDropdownChange(event, setFieldValue)
                        }
                        inputProps={{ id: 'video-select' }}
                      >
                        {cloudVideoData &&
                          cloudVideoData?.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  )}
                  {(existing == 0 || existing != 1) && (
                    <Div sx={{ mb: 2, mt: 1 }}>
                      <input
                        fullWidth
                        sx={{ width: '650px' }}
                        type="file"
                        id="videoInput"
                        name="url"
                        accept=".mp4, .mov, .avi" // Specify accepted video file types
                        onChange={handleVideoFileInputChange}
                      />
                    </Div>
                  )}
                  <Div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <label style={{ 'font-size': '16px' }}>
                      Interaction Details{' '}
                    </label>

                    {/* <Button variant="contained" onClick={() => setSample(true)}>
                      Add Sample
                    </Button> */}

                    {!props?.data?.interaction && (
                      <Button
                        variant="contained"
                        onClick={() => setSample(true)}
                      >
                        Add Sample
                      </Button>
                    )}
                  </Div>
                  <br />

                  <Div>
                    {' '}
                    {sample || props?.data?.interaction ? (
                      <div style={{ backgroundColor: 'black' }}>
                        <TextField
                          multiline
                          rows={10}
                          name="interaction"
                          variant="outlined"
                          fullWidth
                          value={jsonData}
                          onChange={event => {
                            handleJsonStringChange(event);
                            // setFieldValue('interaction', jsonData);
                          }}
                          InputProps={{
                            style: {
                              whiteSpace: 'pre-wrap',
                              color: 'white',
                            },
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ backgroundColor: 'black' }}>
                        <TextField
                          multiline
                          rows={5}
                          name="interaction"
                          variant="outlined"
                          fullWidth
                          onChange={event => {
                            handleJsonStringChange(event);
                            setFieldValue('interaction', event.target.value);
                          }}
                          InputProps={{
                            style: {
                              whiteSpace: 'pre-wrap',
                              color: 'white',
                            },
                          }}
                        />
                      </div>
                    )}
                  </Div>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" type="submit" autoFocus>
                  {props?.data?.id ? 'Update' : 'Add'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedVideo('');
                    props.close();
                  }}
                >
                  {' '}
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddVideos;
