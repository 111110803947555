import React, { Suspense } from 'react';
import { IconButton } from '@mui/material';
import menus from './menus';
import JumboVerticalNavbar from '@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar';
import { DrawerHeader } from '@jumbo/components/JumboLayout/style';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar';
import useJumboSidebarTheme from '@jumbo/hooks/useJumboSidebarTheme';
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from '@jumbo/utils/constants/layout';
import Logo from '../../../../shared/Logo';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from '@mui/material/Zoom';
import Div from '@jumbo/shared/Div';
import SidebarSkeleton from './SidebarSkeleton';
import auth from 'modules/auth';
import { useSelector } from 'react-redux';
import userMenus from './userMenu';
import MasterAdminMenu from './masterAdminMenu';
import { useState } from 'react';
import Batches from 'app/mmEsoft/module/Batches';
import { useEffect } from 'react';
// import MarvelMindsApi from './MarvelMindsApi';
// import MarvelMindsApi from 'app/mmEsoft/module/MarvelMindsApi';
// import getBatches from "../../../../mmEsoft/assets/getBatchesByUserId.json";

const Sidebar = () => {
  const [userBatches, setUserBatches] = useState([]);
  const { isAuthenticated, user } = useSelector(({ login }) => login);
  const { campaignList } = useSelector(
    ({ campaignsReducer }) => campaignsReducer
  );
  const [newSideBarItems, setNewSideBarItems] = React.useState([]);
  React.useEffect(() => {
    if ((user?.roleId === 3 || user?.roleId === 5) && isAuthenticated) {
      if (campaignList.length > 0) {
        userMenus[1].children = campaignList.map((item, index) => ({
          label: ` ${item.title}`,
          uri: '/courses/' + item.id,
          type: 'nav-item',
        }));
      }
      setNewSideBarItems(userMenus);
    } else if (user?.roleId === 1 && isAuthenticated) {
      setNewSideBarItems(menus);
    } else if (user?.roleId === 0 && isAuthenticated) {
      setNewSideBarItems(MasterAdminMenu);
    }
  }, [isAuthenticated, campaignList]);

  return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                alignContent: 'center',
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={newSideBarItems} />
        </Suspense>
      </JumboScrollbar>
    </React.Fragment>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge='start'
                color='inherit'
                aria-label='open drawer'
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          )}
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

export default Sidebar;

//
